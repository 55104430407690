// routes.js
import React from 'react';
import ReceiptViewController from './controllers/receiptviewController';
import AboutController from './controllers/AboutController';
import LogoutController from './controllers/LogoutController';
import DashboardController from './controllers/DashboardController';
import HomeView from './views/homeview';
import Colors from './views/theme/colors/Colors';
import Typography from './views/theme/typography/Typography';
import Accordion from './views/base/accordion/Accordion';
import Breadcrumbs from './views/base/breadcrumbs/Breadcrumbs';
import Cards from './views/base/cards/Cards';
import Carousels from './views/base/carousels/Carousels';
import Collapses from './views/base/collapses/Collapses';
import ListGroups from './views/base/list-groups/ListGroups';
import Navs from './views/base/navs/Navs';
import Paginations from './views/base/paginations/Paginations';
import Placeholders from './views/base/placeholders/Placeholders';
import Popovers from './views/base/popovers/Popovers';
import Progress from './views/base/progress/Progress';
import Spinners from './views/base/spinners/Spinners';
import Tables from './views/base/tables/Tables';
import Tooltips from './views/base/tooltips/Tooltips';
import Buttons from './views/buttons/buttons/Buttons';
import ButtonGroups from './views/buttons/button-groups/ButtonGroups';
import Dropdowns from './views/buttons/dropdowns/Dropdowns';
import ChecksRadios from './views/forms/checks-radios/ChecksRadios';
import FloatingLabels from './views/forms/floating-labels/FloatingLabels';
import FormControl from './views/forms/form-control/FormControl';
import InputGroup from './views/forms/input-group/InputGroup';
import Layout from './views/forms/layout/Layout';
import Range from './views/forms/range/Range';
import Select from './views/forms/select/Select';
import Validation from './views/forms/validation/Validation';
import Charts from './views/charts/Charts';
import CoreUIIcons from './views/icons/coreui-icons/CoreUIIcons';
import Flags from './views/icons/flags/Flags';
import Brands from './views/icons/brands/Brands';
import Alerts from './views/notifications/alerts/Alerts';
import Badges from './views/notifications/badges/Badges';
import Modals from './views/notifications/modals/Modals';
import Toasts from './views/notifications/toasts/Toasts';
import Widgets from './views/widgets/Widgets';
import LoginController from './controllers/LoginController.js';
import CustomerView from './controllers/DashboardController.js';




const routes = [
    { path: '/dashboard', element: <CustomerView /> },
    { path: '/about', element: <AboutController /> },
    { path: '/login', element: <LoginController /> },
    { path: '/logout', element: <LogoutController /> },
    { path: '/dashboard', element: <DashboardController /> },
    { path: '/:code', element: <ReceiptViewController /> },
    { path: '/', element: <HomeView /> },
    { path: '/theme', element: <Colors /> },
    { path: '/theme/colors', element: <Colors /> },
    { path: '/theme/typography', element: <Typography /> },
    { path: '/base', element: <Cards /> },
    { path: '/base/accordion', element: <Accordion /> },
    { path: '/base/breadcrumbs', element: <Breadcrumbs /> },
    { path: '/base/cards', element: <Cards /> },
    { path: '/base/carousels', element: <Carousels /> },
    { path: '/base/collapses', element: <Collapses /> },
    { path: '/base/list-groups', element: <ListGroups /> },
    { path: '/base/navs', element: <Navs /> },
    { path: '/base/paginations', element: <Paginations /> },
    { path: '/base/placeholders', element: <Placeholders /> },
    { path: '/base/popovers', element: <Popovers /> },
    { path: '/base/progress', element: <Progress /> },
    { path: '/base/spinners', element: <Spinners /> },
    { path: '/base/tables', element: <Tables /> },
    { path: '/base/tooltips', element: <Tooltips /> },
    { path: '/buttons', element: <Buttons /> },
    { path: '/buttons/buttons', element: <Buttons /> },
    { path: '/buttons/dropdowns', element: <Dropdowns /> },
    { path: '/buttons/button-groups', element: <ButtonGroups /> },
    { path: '/charts', element: <Charts /> },
    { path: '/forms', element: <FormControl /> },
    { path: '/forms/form-control', element: <FormControl /> },
    { path: '/forms/select', element: <Select /> },
    { path: '/forms/checks-radios', element: <ChecksRadios /> },
    { path: '/forms/range', element: <Range /> },
    { path: '/forms/input-group', element: <InputGroup /> },
    { path: '/forms/floating-labels', element: <FloatingLabels /> },
    { path: '/forms/layout', element: <Layout /> },
    { path: '/forms/validation', element: <Validation /> },
    { path: '/icons', element: <CoreUIIcons /> },
    { path: '/icons/coreui-icons', element: <CoreUIIcons /> },
    { path: '/icons/flags', element: <Flags /> },
    { path: '/icons/brands', element: <Brands /> },
    { path: '/notifications', element: <Alerts /> },
    { path: '/notifications/alerts', element: <Alerts /> },
    { path: '/notifications/badges', element: <Badges /> },
    { path: '/notifications/modals', element: <Modals /> },
    { path: '/notifications/toasts', element: <Toasts /> },
    { path: '/widgets', element: <Widgets /> },
];

export default routes;
