import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../components/header/footer';
import '../customerview.css';
import AppHeaderDropdown from '../components/header/MainHeader';
import DataTableComponent from './dataTableComponent';
import BranchesTable from './BranchesDataTable';
import ReviewsTable from './reviewsDataTable';
import $ from 'jquery';
import Swal from 'sweetalert2';
import DashboardCharts from './dashboardCharts'; // Adjust the path if necessary


const CustomerView = ({ businessData, reviewsData }) => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const [activeSection, setActiveSection] = useState('dashboard');
    const [menuVisible, setMenuVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [businessId, setBusinessId] = useState(JSON.parse(sessionStorage.getItem('userData')).businessId);
    const UserId = JSON.parse(sessionStorage.getItem('userData')).id;
    const [businessDetaildata, setBusinessData] = useState(null);
    const [searchCode, setSearchCode] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [defaultLogoPath, setDefaultLogoPath] = useState('');
    const [isLogoChanged, setIsLogoChanged] = useState(false);
    const [topBannerAds, setTopBannerAds] = useState([]);
    const [bottomBannerAds, setBottomBannerAds] = useState([]);
    const [formDataTop, setFormDataTop] = useState({ images: [], links: [] });
    const [formDataBottom, setFormDataBottom] = useState({ images: [], links: [] });
    const [ads, setAds] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isLaptop, setIsLaptop] = useState(false);
    const location = useLocation();
    const [branches, setBranches] = useState([]);
    const [requiredDataUser, setrequiredDataUser] = useState([]);
    const [userbranchData, setuserbranchData] = useState([]);
    const [showSubMenu, setShowSubMenu] = useState(true);
    const [branchName, setBranchName] = useState('');
    const [branchPhone, setBranchPhone] = useState('');
    const [branchAddress, setBranchAddress] = useState('');
    const [Branchlogo, setBranchLogo] = useState(null);
    const [branchId, setBranchId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('request');
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [topAdSpeed, setTopAdSpeed] = useState('1000');
    const [bottomAdSpeed, setBottomAdSpeed] = useState('1000');


    useEffect(() => {
        fetchBusinessDetails();
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 767);
            setIsTablet(width > 767 && width <= 1023);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (businessData[0].logo === null) {
            const modal = document.getElementById('invoiceModal');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                modal.setAttribute('aria-modal', 'true');
                modal.removeAttribute('aria-hidden');
            }
        }
    }, [businessData]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const modal = document.getElementById('invoiceModal');
            const modalContent = modal.querySelector('.modal-content');
            if (modal && modal.classList.contains('show') && !modalContent.contains(event.target)) {
                console.log('Outside click detected');
                closeModal();
            }
        };

        if (document.getElementById('invoiceModal').classList.contains('show')) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const closeModal = () => {
        console.log('calling close');
        const modal = document.getElementById('invoiceModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            modal.setAttribute('aria-hidden', 'true');
            modal.removeAttribute('aria-modal');
        }
    };




    useEffect(() => {
        const storedSection = sessionStorage.getItem('activeSection');
        if (storedSection) {
            setActiveSection(storedSection);
            sessionStorage.removeItem('activeSection');
        }
    }, []);
    useEffect(() => {
        if (businessDetaildata && businessDetaildata.logo) {
            setSelectedImage(`/${businessDetaildata.logo}`);
            setDefaultLogoPath(`/${businessDetaildata.logo}`);
        }
        // console.log('business-data for ads', businessDetaildata)
    }, [businessDetaildata]);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png'];
            if (!validTypes.includes(file.type)) {
                setSelectedImage(null);
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid File Type',
                    text: 'Please upload a PNG or JPG image.',
                });
                return;
            }
            if (file.size > 1 * 1024 * 1024) {
                setSelectedImage(null);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'File size should be less than 1 MB.',
                });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
                setIsLogoChanged(true);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedImage(null);
            setIsLogoChanged(false);
        }
    };


    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, i) => (
            <i key={i + 1} className={`fa fa-star${i < rating ? ' filled' : ''}`} />
        ));
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % reviewsData.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [reviewsData.length]);


    // fetch meanu data 
    const fetchInvoices = async () => {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-invoices/${businessId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            setInvoices(data);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBusinessDetails = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/business/${businessId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            setBusinessData(data);
        } catch (error) {
            console.error("Error fetching business details:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (activeSection === 'business-settings') {
            fetchBusinessDetails();
        }
    }, [activeSection]);

    const updateBusinessDetails = async (updatedData) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/business/${businessId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            setBusinessData(data);
            sessionStorage.setItem('activeSection', 'business-settings');
            window.location.reload();
            Swal.fire({
                icon: 'success',
                title: 'Update Successful',
                text: 'Business details have been updated.',
            });
        } catch (error) {
            console.error("Error updating business details:", error);
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'Failed to update business details.',
            });
        } finally {
            setLoading(false);
        }
    };


    const insertBusinessDetails = async (InsertBusinessData) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/insert-business/${UserId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(InsertBusinessData),
            });

            if (!response.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error Inserting Business.',
                });
                throw new Error('Failed to insert business');
            }
            const data = await response.json();
            const userData = {
                id: data.user.id,
                email: data.user.email,
                name: data.user.name,
                businessId: data.user.businessId,
                contact: data.user.contact
            };
            Swal.fire({
                icon: 'success',
                title: 'Insert Successful',
                text: 'Business details have been added successfully.',
            }).then(() => {
                sessionStorage.setItem('userData', JSON.stringify(userData));
                setBusinessData(data);
                sessionStorage.setItem('activeSection', 'business-settings');
                window.location.reload();
            });

        } catch (error) {
            console.error("Error inserting business details:", error);
            Swal.fire({
                icon: 'error',
                title: 'Insert Failed',
                text: 'Failed to insert business details.',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/search-invoice/${searchCode}/${businessId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Invoice not found');
            }

            const data = await response.json();
            if (data.data && data.data.length > 0) {
                const uniqueCode = data.data[0].unique_code;
                // navigate(`/${uniqueCode}`);
                window.open(`/${uniqueCode}`, '_blank');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Invoice Not Found',
                    text: 'The invoice with the provided number does not exist.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Invoice Not Found',
                text: 'The invoice with the provided number does not exist.',
            });
        }
    };

    const handleSocialSubmit = async (socialLinks) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/social-links/${businessId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(socialLinks),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Social links have been updated successfully.',
            });
        } catch (error) {
            console.error('Error submitting social links:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update social links.',
            });
        } finally {
            setLoading(false);
        }
    };
    const [socialLinks, setSocialLinks] = useState({
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        tiktok: '',
        snapchat: '',
        whatsapp: '',
        youtube: '',
        pinterest: '',
        telegram: ''
    });

    const fetchSocialLinks = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/social-links/${businessId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (!data.success) {
            }
            setSocialLinks({
                facebook: data.data.facebook || '',
                twitter: data.data.twitter || '',
                instagram: data.data.instagram || '',
                linkedin: data.data.linkedin || '',
                tiktok: data.data.tiktok || '',
                snapchat: data.data.snapchat || '',
                whatsapp: data.data.whatsapp || '',
                youtube: data.data.youtube || '',
                pinterest: data.data.pinterest || '',
                telegram: data.data.telegram || ''
            });
        } catch (error) {
            console.error('Error fetching social links:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (activeSection === 'socials') {
            fetchSocialLinks();
        }
        if (activeSection === 'dashboard') {
            fetchBranches();
        }
    }, [activeSection]);
    const cloneUploadContainer = (position) => {
        // console.log('Cloning upload container for position:', position);
        const originalUploadContainer = document.querySelector(`#upload-container-${position}`);
        if (!originalUploadContainer) {
            console.error('Original upload container not found!');
            return;
        } else {
            originalUploadContainer.style.display = 'none';  // Hide the original upload container
        }

        const OriginalfileInput = originalUploadContainer.querySelector(`#choose-file-${position}`);
        if (!OriginalfileInput) {
            console.error('File input not found in original container!');
            return;
        }
        // Remove any existing cloned container
        const existingClonedContainer = document.querySelector(`.upload-container-clone-${position}`);
        if (existingClonedContainer) {
            existingClonedContainer.remove();
        }

        // Clone the original container
        const clonedContainer = originalUploadContainer.cloneNode(true);
        clonedContainer.classList.add(`upload-container-clone-${position}`);
        clonedContainer.style.display = 'flex';

        // Find the file input in the cloned container
        const fileInput = clonedContainer.querySelector(`#choose-file-${position}`);
        if (!fileInput) {
            console.error('File input not found in cloned container!');
            return;
        }

        // Update the ID for the cloned file input to be unique
        fileInput.id = `choose-file-clone-${position}`;

        // Make the cloned container clickable
        clonedContainer.addEventListener('click', () => {
            fileInput.click();
        });

        // Attach the change event listener to the file input in the cloned container
        fileInput.addEventListener('change', (e) => handleAdsImageChange(e, position));

        // Add drag and drop functionality
        clonedContainer.addEventListener('dragover', (e) => e.preventDefault());
        clonedContainer.addEventListener('drop', (e) => handleDrop(e, position));

        // Find the div where the cloned container should be appended
        const clonedContainerDiv = document.querySelector(`#cloned-container-${position}`);

        if (clonedContainerDiv) {
            // console.log(`Appending cloned container to ${clonedContainerDiv.id}`);
            clonedContainerDiv.appendChild(clonedContainer);
        } else {
            console.error(`Cloned container div not found for position: ${position}`);
        }
    };




    const fetchAds = async () => {
        const originalUploadContainerTop = document.querySelector('#upload-container-top');
        const originalUploadContainerBottom = document.querySelector('#upload-container-bottom');
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/business-ads/${businessId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            const speeds = data.speeds || { bottom_slider_speed: '1000', top_slider_speed: '1000' };
            if (data.data && data.data.length > 0) {
                const topAds = data.data.filter(ad => ad.banner_position === 'top');
                const bottomAds = data.data.filter(ad => ad.banner_position === 'bottom');

                if (bottomAds.length > 0) {
                    cloneUploadContainer('bottom');
                    originalUploadContainerBottom.style.display = 'none';
                }
                if (topAds.length > 0) {
                    cloneUploadContainer('top');
                    originalUploadContainerTop.style.display = 'none';
                }

            }
            setTopAdSpeed(speeds.top_slider_speed);
            setBottomAdSpeed(speeds.bottom_slider_speed);
            setAds(data.data || []);

        } catch (error) {
            console.error("Error fetching ads:", error);
        } finally {
            setLoading(false);
        }
    };


    const removeClonedContainer = (position) => {
        const clonedContainer = document.querySelector(`.upload-container-clone-${position}`);
        if (clonedContainer) {
            // console.log(`Removing cloned container for position: ${position}`);
            clonedContainer.remove();
        } else {
            // console.log(`No cloned container to remove for position: ${position}`);
        }

        // Show the original upload container if there are no images left
        if (position === 'top' ? formDataTop.images.length === 0 : formDataBottom.images.length === 0) {
            const uploadContainer = document.querySelector(`#upload-container-${position}`);
            if (uploadContainer) {
                // console.log('Showing main upload container again');
                // uploadContainer.style.display = 'block';
            }
        }
    };

    const handleDeleteImage = (index, position) => {
        const originalUploadContainer = document.querySelector(`#upload-container-${position}`);
        const adsPreviewContainer = document.querySelector(`#ads-preview-container-${position}`);
        const adItems = adsPreviewContainer.querySelectorAll('.ad-item');

        const checkAndShowOriginalContainer = () => {
            const adItems = adsPreviewContainer.querySelectorAll('.ad-item');
            console.log(adItems); // For debugging
            if (adItems.length === 0) {
                // No ad items, show the original container and remove the cloned one if it exists
                originalUploadContainer.style.display = 'block';

                const clonedContainer = document.querySelector(`.upload-container-clone-${position}`);
                if (clonedContainer) {
                    clonedContainer.remove(); // Remove the cloned container if it exists
                }
            }
        };



        if (position === 'top') {
            setFormDataTop((prevData) => {
                const updatedImages = [...prevData.images];
                const updatedLinks = [...prevData.links];
                updatedImages.splice(index, 1);
                updatedLinks.splice(index, 1);
                setTimeout(checkAndShowOriginalContainer, 0);
                return { ...prevData, images: updatedImages, links: updatedLinks };
            });
        } else if (position === 'bottom') {
            setFormDataBottom((prevData) => {
                const updatedImages = [...prevData.images];
                const updatedLinks = [...prevData.links];
                updatedImages.splice(index, 1);
                updatedLinks.splice(index, 1);
                setTimeout(checkAndShowOriginalContainer, 0);
                return { ...prevData, images: updatedImages, links: updatedLinks };
            });

            // Check if there are no images left
            if (formDataBottom.images.length === 1) {
                // removeClonedContainer('bottom');
                // originalUploadContainer.style.display = 'block';
            }
        } else {
            const updatedAds = [...ads];
            updatedAds.splice(index, 1);
            setAds(updatedAds);
            setTimeout(checkAndShowOriginalContainer, 0);
        }
    };

    const handleAdsImageChange = (e, position) => {
        const files = Array.from(e.target.files);

        // Check if the number of selected files exceeds 5
        if (files.length > 5) {
            Swal.fire({
                icon: 'warning',
                title: 'Limit Exceeded',
                text: 'You can only upload up to 5 images.',
            });
            e.target.value = ''; // Reset the input field
            return;
        }

        // Validate file types and sizes
        const validImageTypes = ['image/png', 'image/jpeg'];
        const invalidFiles = files.filter(file => !validImageTypes.includes(file.type) || file.size > 1 * 1024 * 1024);

        if (invalidFiles.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File',
                text: 'Please select only PNG or JPG images and ensure each file is less than 1MB.',
            });
            e.target.value = ''; // Reset the input field
            return;
        }

        const links = Array(files.length).fill('');

        // Update form data based on position
        if (position === 'top') {
            setFormDataTop(prevData => {
                const updatedImages = [...prevData.images, ...files];
                const updatedLinks = [...prevData.links, ...links];

                if (prevData.images.length === 0) {
                    cloneUploadContainer(position); // Cloning upload container
                }

                return {
                    ...prevData,
                    images: updatedImages,
                    links: updatedLinks,
                };
            });
        } else if (position === 'bottom') {
            setFormDataBottom(prevData => {
                const updatedImages = [...files, ...prevData.images]; // Append new images before existing ones
                const updatedLinks = [...links, ...prevData.links]; // Append new links before existing ones

                if (prevData.images.length === 0) {
                    cloneUploadContainer(position); // Cloning upload container
                }

                return {
                    ...prevData,
                    images: updatedImages,
                    links: updatedLinks,
                };
            });
        }
    };

    // Updated form components (top and bottom) remain unchanged
    const handleDrop = (e, position) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);

        // Check if the number of selected files exceeds 5
        if (files.length > 5) {
            Swal.fire({
                icon: 'warning',
                title: 'Limit Exceeded',
                text: 'You can only upload up to 5 images.',
            });
            return;
        }

        // Check if all files are PNG or JPG
        const validImageTypes = ['image/png', 'image/jpeg'];
        const invalidFiles = files.filter(file => !validImageTypes.includes(file.type) || file.size > 1 * 1024 * 1024);

        if (invalidFiles.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File',
                text: 'Please select only PNG or JPG images and ensure each file is less than 1 MB.',
            });
            return;
        }

        const links = Array(files.length).fill('');

        if (position === 'top') {
            setFormDataTop((prevData) => ({
                ...prevData,
                images: files,
                links: [...links],
            }));
        } else {
            setFormDataBottom((prevData) => ({
                ...prevData,
                images: files,
                links: [...links],
            }));
        }
    };

    const handleLinkChange = (index, position, value, adId = null) => {
        if (position === 'top') {
            // Update links for newly added top images
            setFormDataTop((prevData) => {
                const updatedLinks = [...prevData.links];
                updatedLinks[index] = value.trim(); // Ensure no empty values
                return { ...prevData, links: updatedLinks };
            });
        } else if (position === 'bottom') {
            // Update links for newly added bottom images
            setFormDataBottom((prevData) => {
                const updatedLinks = [...prevData.links];
                updatedLinks[index] = value.trim(); // Ensure no empty values
                return { ...prevData, links: updatedLinks };
            });
        } else if (position === 'current') {
            // Update links for existing ads from the database
            setAds((prevAds) =>
                prevAds.map((ad) =>
                    ad.id_business_ads === adId ? { ...ad, ads_link: value.trim() } : ad
                )
            );
        }
    };



    useEffect(() => {
        if (activeSection !== 'ads') {
            // Clear the form data for both top and bottom ads
            setFormDataTop({ images: [], links: [] });
            setFormDataBottom({ images: [], links: [] });
        }
    }, [activeSection]);


    const handleAdsSubmit = async (e, position) => {
        e.preventDefault();
        setLoading(true);

        const originalUploadContainer = document.querySelector(`#upload-container-${position}`);
        const data = position === 'top' ? formDataTop : formDataBottom;

        // Combine newly added images and existing ads from the database
        const combinedAds = [
            ...data.images.map((image, index) => {
                const link = data.links[index].trim() === '' ? '#' : data.links[index];
                return { image, link, type: 'new' }; // 'new' to indicate it's a newly added image
            }),
            ...ads.filter(ad => ad.banner_position === position).map(ad => {
                return {
                    image: ad.banner_path,
                    link: ad.ads_link,
                    type: 'existing',
                    id_business_ads: ad.id_business_ads
                };
            })
        ];

        // Log the combined ads data
        console.log('Submitting with:', combinedAds);

        if (combinedAds.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'No Ads to Submit',
                text: 'Please make sure all ads have valid links.',
            });
            setLoading(false);
            return;
        }

        const base64Promises = combinedAds.map(({ image, link, type, id_business_ads }) => {
            return new Promise((resolve, reject) => {
                if (type === 'new') {
                    // Convert new image files to base64
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve({
                            base64: reader.result,
                            link: link,
                            type: type,
                            id_business_ads: null // No ID for new images
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(image);
                } else {
                    // Existing image from DB (no need to convert to base64)
                    resolve({
                        base64: image, // Use image path directly
                        link: link,
                        type: type,
                        id_business_ads: id_business_ads // Pass the ID for existing ads
                    });
                }
            });
        });
        const selectedSpeed = position === 'top' ? topAdSpeed : bottomAdSpeed;

        try {
            const base64Images = await Promise.all(base64Promises);
            const formData = new FormData();
            formData.append('slider_speed', selectedSpeed);
            formData.append('banner_position', position); // Include the position

            base64Images.forEach(({ base64, link, type, id_business_ads }) => {
                formData.append('images', base64);
                formData.append('links', link);
                formData.append('type', type); // 'new' or 'existing'
                formData.append('id_business_ads', id_business_ads || ''); // Pass ID for existing ads or empty string
            });

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/insert-ads/${businessId}/${position}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            Swal.fire({
                icon: 'success',
                title: 'Ads Created',
                text: 'Your ads have been successfully created.',
            });

            // Clear form data after submission
            if (position === 'top') {
                setFormDataTop({ images: [], links: [] });
            } else {
                setFormDataBottom({ images: [], links: [] });
            }

            removeClonedContainer(position);
            originalUploadContainer.style.display = 'block';
            fetchAds(); // Refresh the ads list

        } catch (error) {
            console.error('Error creating ads:', error);
            Swal.fire({
                icon: 'error',
                title: 'Ad Creation Failed',
                text: error.message,
            });
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        if (activeSection === 'ads') {
            fetchAds();
        }
    }, [businessId, activeSection]);
    const handleDeleteImageFromDb = async (id) => {
        const originalUploadContainerTop = document.querySelector(`#upload-container-top`);
        const adsPreviewContainerTop = document.querySelector(`#ads-preview-container-top`);
        const adItems = adsPreviewContainerTop.querySelectorAll('.ad-item');

        const originalUploadContainerBottom = document.querySelector(`#upload-container-bottom`);
        const adsPreviewContainerBottom = document.querySelector(`#ads-preview-container-bottom`);
        const adItemsBottom = adsPreviewContainerBottom.querySelectorAll('.ad-item');

        const confirmDelete = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (!confirmDelete.isConfirmed) return; // Exit if the user cancels

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/business-ads/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete ad: ' + response.statusText);
            }

            // Remove the deleted ad from the ads state
            setAds(prevAds => prevAds.filter(ad => ad.id_business_ads !== id));

            const updatedAdItems = adsPreviewContainerTop.querySelectorAll('.ad-item');
            if (updatedAdItems.length === 1) {
                originalUploadContainerTop.style.display = 'block';
                const clonedContainer = document.querySelector(`.upload-container-clone-top`);
                if (clonedContainer) {
                    clonedContainer.remove();
                }
            }


            const updatedAdItemsbottom = adsPreviewContainerBottom.querySelectorAll('.ad-item');
            if (updatedAdItemsbottom.length === 1) {
                originalUploadContainerBottom.style.display = 'block';
                const clonedContainer = document.querySelector(`.upload-container-clone-bottom`);
                if (clonedContainer) {
                    clonedContainer.remove();
                }
            }
            fetchAds();

        } catch (error) {
            console.error("Error deleting ad:", error);
        }
    };



    const [userData, setUserData] = useState({
        id: '',
        name: '',
        email: '',
        contact: '',
    });
    const [password, setPassword] = useState('');

    // Assuming you have a function to get user data from the session
    useEffect(() => {
        const storedUserData = JSON.parse(sessionStorage.getItem('userData'));
        if (storedUserData) {
            setUserData(storedUserData);
        }
    }, []);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEditProfileSubmit = async (e) => {
        e.preventDefault();

        const token = sessionStorage.getItem('token'); // Ensure you have the token

        // No longer fetch userData from sessionStorage; use the state instead
        const { id, name, email, contact } = userData;

        // Check for required fields
        if (!name || !email) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'Name and Email are required fields.',
            });
            return;
        }

        if (password && (password.length < 6 || password.length > 8)) {
            return Swal.fire({
                icon: 'warning',
                title: 'Invalid Password',
                text: 'Password must be between 6 and 8 characters.',
            });
        }

        // Create an object for the updated data using the latest state values
        const updatedData = {
            name,
            email,
            contact,
            ...(password && { password }), // Only include password if provided
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/update_user/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // Specify JSON content type
                },
                body: JSON.stringify(updatedData), // Send the updated data as JSON
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update profile');
            }

            if (response.status === 200) {
                // Clear current session data
                sessionStorage.removeItem('userData');

                // Parse the response data
                const data = await response.json();
                // console.log('Update response:', data); // Log the update response

                // Update userData in session storage
                const newUserData = {
                    id: data.user.id,
                    email: data.user.email,
                    name: data.user.name,
                    businessId: data.user.businessId,
                    contact: data.user.contact,
                };
                sessionStorage.setItem('userData', JSON.stringify(newUserData));

                // Show success message
                Swal.fire({
                    icon: 'success',
                    title: 'Profile Updated',
                    text: 'Your profile has been successfully updated.',
                });

            } else {
                // Handle the error case if status is not 200
                const errorData = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: errorData.message || 'Failed to update profile',
                });
            }

        } catch (error) {
            console.error('Error updating profile:', error);
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: error.message,
            });
        }
    };
    const changestate = () => {
        let Subnav = document.querySelector('.navbar-collapse');
        if (Subnav.classList.contains('show')) {
            Subnav.classList.remove('show'); // Remove the 'show' class if it exists
        }
    };




    const handleSectionChange = (section) => {
        changestate();
        setShowSubMenu(false);
        setMenuVisible(false);
        setActiveSection(section);
        sessionStorage.setItem('activeSection', section);
        navigate(`/dashboard?section=${section}`);
        if (section === 'dashboard') {
            fetchBranches();
        }
        if (section === 'sale-invoices') {
            fetchInvoices();
            fetchRequiredData();
        }
        if (section === 'business-settings') {
            fetchBusinessDetails();
        }
        if (section === 'socials') {
            fetchSocialLinks();
        }

    };

    useEffect(() => {
        if (activeSection === 'socials') {
            fetchSocialLinks();
        }
    }, [activeSection]);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sectionFromUrl = queryParams.get('section');

        if (sectionFromUrl) {
            if (sectionFromUrl === 'sale-invoices') {
                fetchInvoices();
                fetchRequiredData();
            } else if (sectionFromUrl === 'reviews') {
                fetchRequiredData();
            } else if (sectionFromUrl === 'dashboard') {
                fetchBranches();
            }
            setActiveSection(sectionFromUrl);
        } else if (sectionFromUrl === 'socials') {
            fetchSocialLinks();
        }


    }, [location.search])


    const fetchBranches = async () => {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-Branches/${businessId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            setBranches(data);
        } catch (error) {
            console.error("Error fetching Branches:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeSection === 'branch-listing') {
            fetchBranches();
        }
    }, [activeSection]);

    const handleDelete = async (id_branch) => {
        console.log('Delete branch with ID:', id_branch);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-branch/${id_branch}/${businessId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the branch');
            }
            fetchBranches();
        } catch (error) {
            console.error("Error deleting branch:", error);
        }
    };


    const handleFileChangeForBranch = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png'];
            if (!validTypes.includes(file.type)) {
                setBranchLogo(null);
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid File Type',
                    text: 'Please upload a PNG or JPG image.',
                });
                return;
            }
            if (file.size > 1 * 1024 * 1024) {
                setBranchLogo(null);
                Swal.fire({
                    icon: 'error',
                    title: 'File too large',
                    text: 'File size should be less than 1 MB.',
                });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result;

                const base64Length = base64Image.length - (base64Image.indexOf(',') + 1);
                const padding = (base64Image.charAt(base64Image.length - 2) === '=') ? 2 : (base64Image.charAt(base64Image.length - 1) === '=') ? 1 : 0;
                const imageSizeInBytes = (base64Length * 3 / 4) - padding;
                if (imageSizeInBytes > 1 * 1024 * 1024) {
                    setBranchLogo(null);
                    Swal.fire({
                        icon: 'error',
                        title: 'File too large',
                        text: 'The logo image after base64 encoding exceeds the 1 MB size limit.',
                    });
                    return;
                }

                setBranchLogo(base64Image);
            };
            reader.readAsDataURL(file);
        } else {
            setBranchLogo(null);
        }
    };



    const handleBranchSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(process.env.BASE_URL);
        const newBranchData = {
            business_id: businessId,
            branch_name: branchName,
            branch_phone: branchPhone,
            branch_address: branchAddress,
            Branchlogo: Branchlogo || '',
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add-branch/${businessId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(newBranchData),
            });

            if (!response.ok) {
                throw new Error('Failed to add branch');
            }

            const data = await response.json();
            Swal.fire({
                icon: 'success',
                title: 'Branch Added Successfully',
                text: 'The branch has been added.',
            });
            setBranchName('');
            setBranchPhone('');
            setBranchAddress('');
            setBranchLogo(null);
            const fileInput = document.querySelector('#Branchlogo');
            if (fileInput) {
                fileInput.value = '';
            }

            const closeButton = document.querySelector('#addBranchModal .close');
            if (closeButton) {
                closeButton.click();
            }
            fetchBranches();
        } catch (error) {
            console.error("Error adding branch:", error);
            Swal.fire({
                icon: 'error',
                title: 'Add Branch Failed',
                text: 'Failed to add the branch.',
            });
        } finally {
            setLoading(false);
        }
    };


    const fetchRequiredData = async () => {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-required-Data/${UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            if (data.userRequiredData && Object.keys(data.userRequiredData).length > 0) {
                setrequiredDataUser(data.userRequiredData);
                setuserbranchData(data.branchData);
                if (Array.isArray(data.branchData) && data.branchData.length > 0) {
                    setSelectedBranchId(data.branchData[0].id_branch);
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error fetching required data',
                });
            }

        } catch (error) {
            console.error("Error fetching data:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while fetching data',
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (activeSection === 'api-integration') {
            fetchRequiredData();
        }
    }, [activeSection]);


    const handleBranchChange = (event) => {
        setSelectedBranchId(event.target.value);
    };
    const resetAddBranchModal = () => {
        setBranchName('');
        setBranchPhone('');
        setBranchAddress('');
        setBranchLogo(null);
        setIsLogoChanged(false);
        setBranchLogo(null);
        setIsLogoChanged(false);
        const fileInput = document.getElementById('Branchlogo');
        if (fileInput) {
            console.log('shahzad khan');
            fileInput.value = '';
            fileInput.text = '';
        }
    };

    return (
        <>
            <AppHeaderDropdown handleSectionChange={handleSectionChange} />

            <div className="dashboard">
                {/* modal start */}
                <div className="modal fade" id="invoiceModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">

                            <span
                                // id='image-span'
                                className="modal-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                                aria-hidden="true"
                            >
                                <i className="fa fa-times icon-time" aria-hidden="true"></i>
                            </span>
                            {/* <span id='image-span' > <a className="delete-button" onClick={() => handleDeleteImage(index, 'top')} >  <i className="fa fa-times" aria-hidden="true"></i>   </a> </span> */}
                            <div className="modal-body">
                                <div className="row no-gutters">
                                    <div className="col-12 col-md-6 position-relative modal-dialog-scrollable"> {/* Added position-relative */}
                                        <div>
                                            <img
                                                id='modal-setup-img'
                                                src="assets/images/receipt.mexyon.com_6la1k.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 d-flex" id="modal-setup-text">
                                        <h4 className="mb-3">Set up your Business</h4>
                                        <a

                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                handleSectionChange('business-settings');
                                                closeModal();
                                            }}
                                        >
                                            Setup Your Business Settings
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* modal end */}

                <header className="menu-wrap print-hidden">
                    <figure className="user" onClick={() => setMenuVisible(!menuVisible)}>
                        <div className="user-avatar">
                            <img src="images/user.png" alt="default" />
                        </div>
                        <figcaption className='username-fig' >
                            {sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')).name : 'Guest'}
                        </figcaption>
                    </figure>
                    <nav className="sub-menu">
                        <section className="dicover">
                            <h3>
                                Discover</h3>
                            <ul>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'dashboard' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('dashboard');
                                            handleSectionChange('dashboard');
                                        }}>
                                        Dashboard
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'sale-invoices' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSectionChange('sale-invoices');
                                        }}>Sale Invoices</a>
                                </li>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'reviews' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSectionChange('reviews');
                                        }}>Reviews</a>
                                </li>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'search' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSectionChange('search');
                                        }}>Search</a>
                                </li>
                            </ul>

                        </section>
                        <section className="settings">
                            <h3>Settings</h3>
                            <ul>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'business-settings' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('business-settings');
                                            handleSectionChange('business-settings');
                                        }}>Business Settings</a>
                                </li>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'socials' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('socials');
                                            handleSectionChange('socials');
                                        }}>Socials</a>
                                </li>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'ads' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('ads');
                                            handleSectionChange('ads');
                                        }}>Ads Settings</a>
                                </li>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'branch-listing' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('business-settings');
                                            handleSectionChange('branch-listing');
                                        }}>Branch Listing</a>
                                </li>
                            </ul>
                        </section>

                        <section className="settings">
                            <h3>Integrations</h3>
                            <ul>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'api-integration' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('business-settings');
                                            handleSectionChange('api-integration');
                                        }}>Api Integration</a>
                                </li>
                                {/* <li>
                                    <a href="#"
                                        className={activeSection === 'demoDataSection' ? 'active' : ''}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('business-settings');
                                            handleSectionChange('demoDataSection');
                                        }}>Demo</a>
                                </li> */}
                            </ul>
                        </section>
                        {/* <section className="Ads Management" >
                            <h3>Ads Management</h3>
                            <ul>
                                <li>
                                    <a href="#"
                                        className={activeSection === 'ads' ? 'active' : ''}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveSection('ads');
                                        }}>Ads Settings</a>
                                </li>
                            </ul>
                        </section> */}

                        {/* <section className="Logs" >
                            <h3>Logs</h3>
                            <ul>
                                <li>
                                    <a href="#"
                                        className={activeSection === 'smslogs' ? 'active' : ''}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('smslogs');
                                            handleSectionChange('smslogs');
                                        }}>Sms Logs</a>
                                </li>
                            </ul>
                        </section> */}


                        <section className="dicover">
                            <h3>Profile</h3>
                            <ul>
                                <li>
                                    <a
                                        className={`on-mouse-hover ${activeSection === 'edit-profile' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // setActiveSection('edit-profile');
                                            handleSectionChange('edit-profile');
                                        }}>Edit Profile</a>
                                </li>
                                <li>
                                    <a className="on-mouse-hover" style={{ color: '#99a0b0' }} onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = "/logout"; // or use navigate('/logout') if using `useNavigate()`
                                    }}>
                                        <i className="fa fa-sign-out" /> Logout
                                    </a>
                                </li>

                            </ul>

                        </section>
                        {/* ... Other sections ... */}
                    </nav>
                </header>

                <main className="content-wrap">
                    {activeSection === 'dashboard' && (
                        <>
                            {/* <header className="row mb-5">
                                <div className='col-12'>
                                    <i className="fa fa-gear pull-right" style={{ fontSize: '50px' }}></i>
                                </div>
                            </header> */}

                            <div className="content">
                                <section className="info-boxes">
                                    <div className="info-box pointer-class" onClick={() => handleSectionChange('sale-invoices')} >
                                        <div className="box-icon">
                                            <img width="48" height="48" src="https://img.icons8.com/ios-filled/48/81a683/graph.png" alt="graph" />
                                        </div>
                                        <div className="box-content">
                                            <span className="big">
                                                {businessData.length > 0 && businessData[0].total_sales != null ? businessData[0].total_sales : 0}
                                            </span>
                                            Total Sales
                                        </div>
                                    </div>

                                    <div className="info-box pointer-class" onClick={() => handleSectionChange('reviews')} >
                                        <div className="box-icon">
                                            <img width="48" height="48" src="https://img.icons8.com/81a683/48/star--v1.png" alt="star--v1" />
                                        </div>
                                        <div className="box-content">
                                            <span className="big">
                                                {businessData.length > 0 && businessData[0].average_rating !== null ? businessData[0].average_rating : 0}
                                            </span>
                                            Total Ratings
                                        </div>
                                    </div>

                                    <div className="info-box pointer-class active" onClick={() => handleSectionChange('sale-invoices')} >
                                        <div className="box-icon">
                                            <img width="48" height="48" src="https://img.icons8.com/ios/48/81a683/paid-bill.png" alt="topic--v1" />
                                        </div>
                                        <div className="box-content">
                                            <span className="big">
                                                {businessData.length > 0 && businessData[0].count_of_invoices !== undefined ? businessData[0].count_of_invoices : 0}
                                            </span>
                                            Total Invoices
                                        </div>
                                    </div>

                                    <div className="info-box pointer-class" onClick={() => handleSectionChange('reviews')} >
                                        <div className="box-icon">
                                            <img width="48" height="48" src="https://img.icons8.com/ios/48/81a683/topic--v1.png" alt="topic--v1" />
                                        </div>
                                        <div className="box-content">
                                            <span className="big">
                                                {businessData.length > 0 && businessData[0].count_of_reviews !== undefined ? businessData[0].count_of_reviews : 0}
                                            </span>
                                            Total Reviews
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <section className="dashboard-charts">
                                <DashboardCharts branches={branches} />
                            </section>

                            <h2 className="text-center mb-5">Recent Reviews</h2>
                            <div className="review-section" style={{ overFlow: 'hidden' }} >
                                <div className="review-slider" style={{
                                    display: 'flex',
                                    // overflow: 'hidden',
                                    transform: `translateX(-${activeIndex * (isMobile ? 50 : isTablet ? 33 : 25)}%)`, // Adjust percentage based on screen size
                                    transition: 'transform 0.5s ease-in-out',
                                }}>
                                    {reviewsData.map((review, index) => (
                                        <div className="col-md-4" key={index}>
                                            <a
                                                target='blank'
                                                rel="noopener noreferrer"
                                                href={`/${review.unique_code}`}
                                            >
                                                <div
                                                    className={`review-card ${index === activeIndex ? 'active' : ''}`}
                                                >
                                                    <div className="quote-icon top-left">
                                                        <i className="fa fa-quote-left"></i>
                                                    </div>
                                                    <div className="quote-icon bottom-right">
                                                        <i className="fa fa-quote-right"></i>
                                                    </div>
                                                    <div className="quote-container">
                                                        <p className="card-text">{review.review}</p>
                                                        <p className="card-text-after">Read more</p>
                                                        <div className="rating">{renderStars(review.rating)}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </>
                    )}
                    {activeSection === 'sale-invoices' && (
                        <>
                            <>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (

                                    <section className="sale-invoices-sec">
                                        <DataTableComponent businessId={businessId} token={token} userbranchData={userbranchData} />
                                    </section>
                                )}

                            </>
                        </>
                    )}
                    {activeSection === 'search' && (
                        <>
                            <section className="search-sec " >
                                <div className="content search-content">
                                    <form onSubmit={handleSearch} className="search-form">
                                        <input
                                            type="text"
                                            className='form-control'
                                            value={searchCode}
                                            onChange={(e) => setSearchCode(e.target.value)}
                                            placeholder="Enter invoice number"
                                            required
                                        />
                                        <button className='btn btn-primary mt-4' style={{ width: '30%' }} type="submit">Search</button>
                                    </form>
                                    <br />
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <p></p> // Add content for the false case
                                    )}
                                </div>
                            </section>

                        </>
                    )}
                    {activeSection === 'business-settings' && (
                        <>

                            <section className="businesssettigs-sec ">
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            const updatedData = {
                                                business_id: businessDetaildata ? businessDetaildata.business_id : null,
                                                business_name: e.target.business_name.value,
                                                business_address: e.target.business_address.value,
                                                business_phone: e.target.business_phone.value,
                                                logo: selectedImage ? selectedImage : '',
                                                website: e.target.website.value,
                                                policy: e.target.policy.value,
                                                primary_color: e.target.primary_color.value,
                                                secondary_color: e.target.secondary_color.value,
                                                primary_highlight_color: e.target.primary_highlight_color.value,
                                                secondary_highlight_color: e.target.secondary_highlight_color.value
                                            };
                                            const defaultLogoPath = businessDetaildata ? businessDetaildata.logo : null;

                                            if (isLogoChanged) {
                                                updatedData.logo = selectedImage;
                                            } else {
                                                updatedData.logo = null;
                                            }
                                            if (updatedData.logo) {
                                                const base64Length = updatedData.logo.length - (updatedData.logo.indexOf(',') + 1);
                                                const padding = (updatedData.logo.charAt(updatedData.logo.length - 2) === '=') ? 2 : (updatedData.logo.charAt(updatedData.logo.length - 1) === '=') ? 1 : 0;
                                                const logoSizeInBytes = (base64Length * 3 / 4) - padding;

                                                if (logoSizeInBytes > 1 * 1024 * 1024) {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'File too large',
                                                        text: 'The logo file must be less than 2 MB.',
                                                    });
                                                    return;
                                                }
                                            }

                                            if (businessDetaildata) {
                                                updateBusinessDetails(updatedData);
                                            } else {
                                                insertBusinessDetails(updatedData);
                                            }
                                        }}
                                        encType="multipart/form-data"
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="business_name">Business Name:</label>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="business_name"
                                                    defaultValue={businessDetaildata ? businessDetaildata.business_name : ''}
                                                    required
                                                />
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="business_address">Business Address:</label>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="business_address"
                                                    defaultValue={businessDetaildata ? businessDetaildata.business_address : ''}
                                                />
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="business_phone">Business Phone:</label>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="business_phone"
                                                    defaultValue={businessDetaildata ? businessDetaildata.business_phone : ''}
                                                />
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="website">Website:</label>
                                                <input
                                                    className='form-control'
                                                    type="url"
                                                    name="website"
                                                    defaultValue={businessDetaildata ? businessDetaildata.website : ''}
                                                />
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="policy">Policy:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="policy"
                                                    rows={8}
                                                    defaultValue={businessDetaildata ? businessDetaildata.policy : ''}
                                                />
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="logo">Upload Logo:</label>
                                                <div className="file-upload-box" onClick={() => document.getElementById('logo').click()}>
                                                    <img
                                                        src={selectedImage}
                                                        className="logo-image"
                                                    />
                                                    <div className="placeholder">
                                                        <span className="add-icon">+</span>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        name="logo"
                                                        accept="image/png, image/jpeg"
                                                        onChange={handleFileChange}
                                                        id="logo"
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="primary_color">Primary Color:</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className='form-control'
                                                        type="color"
                                                        name="primary_color_picker"
                                                        defaultValue={businessDetaildata ? businessDetaildata.primary_color : '#000000'}
                                                        onChange={(e) => {
                                                            document.getElementsByName('primary_color')[0].value = e.target.value;
                                                        }}
                                                    />
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        name="primary_color"
                                                        defaultValue={businessDetaildata ? businessDetaildata.primary_color : '#000000'}
                                                        style={{ marginLeft: '10px', width: '80px' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="secondary_color">Secondary Color:</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="color"
                                                        className='form-control'
                                                        name="secondary_color_picker"
                                                        defaultValue={businessDetaildata ? businessDetaildata.secondary_color : '#FFFFFF'}
                                                        onChange={(e) => {
                                                            document.getElementsByName('secondary_color')[0].value = e.target.value;
                                                        }}
                                                    />
                                                    <input
                                                        type="text"
                                                        className='form-control'
                                                        name="secondary_color"
                                                        defaultValue={businessDetaildata ? businessDetaildata.secondary_color : '#FFFFFF'}
                                                        style={{ marginLeft: '10px', width: '100px' }}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="primary_highlight_color">Primary Highlight Color:</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className='form-control'
                                                        type="color"
                                                        name="primary_highlight_color_picker"
                                                        defaultValue={businessDetaildata ? businessDetaildata.primary_highlight_color : '#000000'}
                                                        onChange={(e) => {
                                                            document.getElementsByName('primary_highlight_color')[0].value = e.target.value; // Update corresponding text input
                                                        }}
                                                    />
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        name="primary_highlight_color"
                                                        defaultValue={businessDetaildata ? businessDetaildata.primary_highlight_color : '#000000'}
                                                        style={{ marginLeft: '10px', width: '80px' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-12 form-group">
                                                <label htmlFor="secondary_highlight_color">Secondary Highlight Color:</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="color"
                                                        className='form-control'
                                                        name="secondary_highlight_color_picker"
                                                        defaultValue={businessDetaildata ? businessDetaildata.secondary_highlight_color : '#FFFFFF'}
                                                        onChange={(e) => {
                                                            document.getElementsByName('secondary_highlight_color')[0].value = e.target.value; // Update corresponding text input
                                                        }}
                                                    />
                                                    <input
                                                        type="text"
                                                        className='form-control'
                                                        name="secondary_highlight_color"
                                                        defaultValue={businessDetaildata ? businessDetaildata.secondary_highlight_color : '#FFFFFF'}
                                                        style={{ marginLeft: '10px', width: '100px' }}
                                                    />
                                                </div>
                                            </div>



                                        </div>

                                        <button className='btn btn-primary' type="submit">
                                            {businessDetaildata ? 'Update Business' : 'Add Business'}
                                        </button>
                                    </form>
                                )}
                            </section>

                        </>
                    )}
                    {activeSection === 'socials' && (
                        <>
                            <section className='socials-sec'>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            const socialLinks = {
                                                facebook: e.target.facebook.value,
                                                twitter: e.target.twitter.value,
                                                instagram: e.target.instagram.value,
                                                linkedin: e.target.linkedin.value,
                                                tiktok: e.target.tiktok.value,
                                                snapchat: e.target.snapchat.value,
                                                whatsapp: e.target.whatsapp.value,
                                                youtube: e.target.youtube.value,
                                                pintrest: e.target.pintrest.value,
                                                telegram: e.target.telegram.value
                                            };
                                            handleSocialSubmit(socialLinks);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="facebook">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-facebook-100.png" alt="Facebook" /></i> Facebook:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="facebook"
                                                    placeholder="Enter Facebook link"
                                                    value={socialLinks.facebook}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, facebook: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="twitter">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-twitter-100.png" alt="Twitter" /></i> Twitter:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="twitter"
                                                    placeholder="Enter Twitter link"
                                                    value={socialLinks.twitter}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, twitter: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="instagram">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-instagram-100.png" alt="Instagram" /></i> Instagram:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="instagram"
                                                    placeholder="Enter Instagram link"
                                                    value={socialLinks.instagram}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, instagram: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="linkedin">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-linkedin-100.png" alt="LinkedIn" /></i> LinkedIn:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="linkedin"
                                                    placeholder="Enter LinkedIn link"
                                                    value={socialLinks.linkedin}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, linkedin: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="tiktok">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-tiktok-100.png" alt="TikTok" /></i> TikTok:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="tiktok"
                                                    placeholder="Enter TikTok link"
                                                    value={socialLinks.tiktok}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, tiktok: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="snapchat">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-snapchat-squared-100.png" alt="Snapchat" /></i> Snapchat:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="snapchat"
                                                    placeholder="Enter Snapchat link"
                                                    value={socialLinks.snapchat}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, snapchat: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="whatsapp">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-whatsapp-128.png" alt="WhatsApp" /></i> WhatsApp:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="whatsapp"
                                                    placeholder="Enter WhatsApp link"
                                                    value={socialLinks.whatsapp}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, whatsapp: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="youtube">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-youtube-100.png" alt="YouTube" /></i> YouTube:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="youtube"
                                                    placeholder="Enter YouTube link"
                                                    value={socialLinks.youtube}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, youtube: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="pintrest">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-pinterest-100.png" alt="Pinterest" /></i> Pinterest:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="pintrest"
                                                    placeholder="Enter Pinterest link"
                                                    value={socialLinks.pintrest}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, pintrest: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label htmlFor="telegram">
                                                    <i><img style={{ height: '30px', width: '30px' }} src="assets/icons/icons8-telegram-100.png" alt="Telegram" /></i> Telegram:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="url"
                                                    name="telegram"
                                                    placeholder="Enter Telegram link"
                                                    value={socialLinks.telegram}
                                                    onChange={(e) => setSocialLinks({ ...socialLinks, telegram: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary" type="submit">
                                            Save Social Links
                                        </button>
                                    </form>
                                )}
                            </section>

                        </>
                    )}
                    {activeSection === 'ads' && (
                        <>
                            <section className='ads-sec' >
                                <div>
                                    {loading && <p>Loading...</p>}

                                    <form style={{ textAlign: 'center', marginBottom: '40px' }} onSubmit={(e) => handleAdsSubmit(e, 'top')}>
                                        <h3 style={{ marginBottom: '20px' }}  >Top Ads</h3>
                                        <label htmlFor="ad-select" style={{ marginRight: '10px' }}>Top Slider Speed:</label>
                                        <select
                                            id="top-ad-select"
                                            className="form-control"
                                            style={{ display: 'inline-block', width: 'auto', marginBottom: '20px' }}
                                            value={topAdSpeed}
                                            onChange={(e) => setTopAdSpeed(e.target.value)}
                                        >
                                            <option value="1000">1(s)</option>
                                            <option value="2000">2(s)</option>
                                            <option value="3000">3(s)</option>
                                            <option value="4000">4(s)</option>
                                            <option value="5000">5(s)</option>
                                            <option value="6000">6(s)</option>
                                            <option value="7000">7(s)</option>
                                            <option value="8000">8(s)</option>
                                            <option value="9000">9(s)</option>
                                            <option value="10000">10(s)</option>
                                        </select>
                                        <div
                                            className="upload-container" id='upload-container-top'
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, 'top')}
                                            onClick={() => document.getElementById('choose-file-top').click()}
                                        >
                                            <div className="upload-placeholder">
                                                <img src="/assets/icons/icons8-upload-100.png" alt="upload" />
                                                <input
                                                    id="choose-file-top"
                                                    type="file"
                                                    className="choose-file-button"
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    onChange={(e) => handleAdsImageChange(e, 'top')}
                                                />
                                            </div>
                                        </div>
                                        <div className="ads-preview-container" id="ads-preview-container-top">
                                            {formDataTop.images.map((image, index) => (
                                                <div key={index} className="ad-item">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Ad ${index + 1}`}
                                                        className="ad-preview-image"
                                                    />
                                                    <input
                                                        type="url"
                                                        placeholder="Enter ads link"
                                                        value={formDataTop.links[index] || ''}
                                                        onChange={(e) => handleLinkChange(index, 'top', e.target.value)}
                                                        className="ad-link-input form-control"
                                                    />
                                                    <span id='image-span' > <a className="delete-button" onClick={() => handleDeleteImage(index, 'top')} >  <i className="fa fa-times" aria-hidden="true"></i>   </a> </span>
                                                </div>
                                            ))}

                                            {ads.filter(ad => ad.banner_position === 'top').map((ad, index) => (
                                                <div key={ad.id_business_ads} className="ad-item">
                                                    <img src={`${ad.banner_path}`} alt="Ad Image" className="ad-preview-image" />
                                                    <input
                                                        type="url"
                                                        placeholder="Enter ads link"
                                                        value={ad.ads_link === '#' ? '' : ad.ads_link || ''}
                                                        onChange={(e) => handleLinkChange(index, 'current', e.target.value, ad.id_business_ads)}
                                                        className="ad-link-input form-control"
                                                    />
                                                    <span id='image-span'> <a className="close-button" onClick={() => handleDeleteImageFromDb(ad.id_business_ads)} >  <i className="fa fa-times" aria-hidden="true"></i>   </a> </span>
                                                </div>
                                            ))}
                                            <div id="cloned-container-top"></div>

                                        </div>
                                        <button type="submit" className='btn btn-primary'>Submit Top Ads</button>
                                    </form>

                                    <form style={{ textAlign: 'center', marginBottom: '40px' }} onSubmit={(e) => handleAdsSubmit(e, 'bottom')}>
                                        <h3 style={{ marginBottom: '20px' }} >Bottom Ads</h3>
                                        <label htmlFor="ad-select" style={{ marginRight: '10px' }}>Bottom Slider Speed:</label>
                                        <select
                                            id="bottom-ad-select"
                                            className="form-control"
                                            style={{ display: 'inline-block', width: 'auto', marginBottom: '20px' }}
                                            value={bottomAdSpeed}
                                            onChange={(e) => setBottomAdSpeed(e.target.value)}
                                        >
                                            <option value="1000">1(s)</option>
                                            <option value="2000">2(s)</option>
                                            <option value="3000">3(s)</option>
                                            <option value="4000">4(s)</option>
                                            <option value="5000">5(s)</option>
                                            <option value="6000">6(s)</option>
                                            <option value="7000">7(s)</option>
                                            <option value="8000">8(s)</option>
                                            <option value="9000">9(s)</option>
                                            <option value="10000">10(s)</option>
                                        </select>
                                        <div
                                            className="upload-container" id='upload-container-bottom'
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, 'bottom')}
                                            onClick={() => document.getElementById('choose-file-bottom').click()}
                                        >
                                            <div className="upload-placeholder">
                                                <img src="/assets/icons/icons8-upload-100.png" alt="upload" />
                                                <input
                                                    id="choose-file-bottom"
                                                    type="file"
                                                    className="choose-file-button"
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    onChange={(e) => handleAdsImageChange(e, 'bottom')}

                                                />
                                            </div>
                                        </div>

                                        <div className="ads-preview-container" id="ads-preview-container-bottom">
                                            {formDataBottom.images.map((image, index) => (
                                                <div key={index} className="ad-item">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Ad ${index + 1}`}
                                                        className="ad-preview-image"
                                                    />
                                                    <input
                                                        type="url"
                                                        placeholder="Enter ads link"
                                                        value={formDataBottom.links[index] || ''}
                                                        onChange={(e) => handleLinkChange(index, 'bottom', e.target.value)}
                                                        className="ad-link-input form-control"
                                                    />
                                                    <span id='image-span'> <a className="delete-button" onClick={() => handleDeleteImage(index, 'bottom')} >  <i className="fa fa-times" aria-hidden="true"></i>   </a> </span>
                                                </div>
                                            ))}

                                            {ads.filter(ad => ad.banner_position === 'bottom').map((ad, index) => (
                                                <div key={ad.id_business_ads} className="ad-item">
                                                    <img src={`${ad.banner_path}`} alt="Ad Image" className="ad-preview-image" />
                                                    <input
                                                        type="url"
                                                        placeholder="Enter ads link"
                                                        value={ad.ads_link === '#' ? '' : ad.ads_link || ''}
                                                        onChange={(e) => handleLinkChange(index, 'current', e.target.value, ad.id_business_ads)}
                                                        className="ad-link-input form-control"
                                                    />
                                                    <span id='image-span'> <a className="close-button" onClick={() => handleDeleteImageFromDb(ad.id_business_ads)} >  <i className="fa fa-times" aria-hidden="true"></i>   </a> </span>
                                                </div>
                                            ))}
                                            <div id="cloned-container-bottom"></div>
                                        </div>

                                        <button type="submit" className='btn btn-primary' >Submit Bottom Ads</button>
                                    </form>
                                </div>
                            </section>
                        </>
                    )}
                    {activeSection === 'smslogs' && (
                        <>
                            <section className="smslogs-sec" >
                                <p>information here.</p>
                            </section>
                        </>
                    )}
                    {activeSection === 'edit-profile' && (
                        <>
                            <section className='editProfile-sec' >
                                {/* <p>Edit your profile information here.</p> */}
                                <form onSubmit={handleEditProfileSubmit}>
                                    <div className="row">
                                        <div className="form-group col-lg-6 col-12"> {/* Adjust the col-md-6 for 2 fields per row */}
                                            <label htmlFor="userName">User Name</label>
                                            <input
                                                type="text"
                                                id="edituserName"
                                                value={userData.name || ''}
                                                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                                className='form-control'
                                                required
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className="form-group col-lg-6 col-12"> {/* Adjust the col-md-6 for 2 fields per row */}
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                id="editemail"
                                                value={userData.email || ''}
                                                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                className='form-control'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6 col-12"> {/* Adjust the col-md-6 for 2 fields per row */}
                                            <label htmlFor="contact">Contact Number</label>
                                            <input
                                                type="number"
                                                id="editcontact"
                                                value={userData.contact || ''}
                                                onChange={(e) => setUserData({ ...userData, contact: e.target.value })}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className="form-group col-lg-6 col-12"> {/* Adjust the col-md-6 for 2 fields per row */}
                                            <label htmlFor="password">Change Password</label>
                                            <div className="input-group">
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="editpassword"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="form-control"
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    onClick={togglePasswordVisibility}
                                                    aria-label="Toggle password visibility"
                                                >
                                                    {showPassword ? '🙈' : '👁️'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn btn-primary' type="submit">Update Profile</button>
                                </form>

                            </section>
                        </>
                    )}
                    {activeSection === 'branch-listing' && (
                        <>

                            {/*Add Branches-Form*/}
                            <div className="modal fade" id="addBranchModal" tabIndex="-1" role="dialog" aria-labelledby="addBranchModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="addBranchModalLabel">Add Branch</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form id='add_branch_form' onSubmit={handleBranchSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="branch_name">Branch Name:</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="branch_name"
                                                        value={branchName}
                                                        onChange={(e) => setBranchName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="branch_phone">Branch Phone:</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="branch_phone"
                                                        value={branchPhone}
                                                        onChange={(e) => setBranchPhone(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="branch_address">Branch Address:</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="branch_address"
                                                        value={branchAddress}
                                                        onChange={(e) => setBranchAddress(e.target.value)}
                                                        rows="1"  // Set the number of visible rows for the textarea
                                                        placeholder="Enter branch address"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="logo">Logo:</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="Branchlogo"
                                                        accept="image/*"
                                                        onChange={handleFileChangeForBranch}
                                                    />
                                                    {Branchlogo && (
                                                        <div style={{ marginTop: '10px' }}>
                                                            <img src={Branchlogo} alt="Selected Logo" style={{ width: '100px', height: '100px' }} />
                                                        </div>
                                                    )}
                                                </div>
                                                <button type="submit" className="btn btn-primary">Add Branch</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*End Of Add Branch Form*/}

                            <section className='branch-listing' >
                                <div className='col-12'>
                                    <button
                                        className='btn btn-primary pull-right'
                                        data-toggle="modal"
                                        data-target="#addBranchModal"
                                        onClick={resetAddBranchModal}
                                    >
                                        Add Branch
                                    </button>
                                </div>
                                {branches.length > 0 ? (
                                    <BranchesTable
                                        branches={branches}
                                        onDelete={handleDelete}
                                        onRefresh={fetchBranches}
                                    />
                                ) : (
                                    !loading && <p>No branches found.</p>
                                )}

                            </section>
                        </>
                    )}

                    {activeSection === 'reviews' && (
                        <>
                            <section className='review' >
                                <ReviewsTable businessId={businessId} token={token} userbranchData={userbranchData} />
                            </section>
                        </>
                    )}


                    {activeSection === 'api-integration' && (
                        <section className="requireDataSection">
                            {requiredDataUser && Object.keys(requiredDataUser).length > 0 ? (
                                <div className='row' >
                                    <div className="col-12 text-center required-data-head">
                                        <p className="required-data-desc" style={{ marginBottom: '10px' }}>
                                            <b>Seamlessly integrate with Mexyon Receipt! All you need is your unique User ID, Business ID, and Secret Key to get started.</b>
                                        </p>
                                    </div>

                                    <div className="row mx-auto mt-5 required-data-card">
                                        <div className="data-item col-lg-4 col-md-4 col-sm-12">
                                            <h5 className='mb-1'>User id</h5>
                                            <p className='req-info' >{requiredDataUser.id}</p>
                                            <h5 className='mb-1'>Business id</h5>
                                            <p className='req-info' >{requiredDataUser.businessId}</p>
                                            <h5 className='mb-1'>Branch id</h5>

                                            <div className="form-group">
                                                {/* <label htmlFor="branchSelect">Select Branch</label> */}
                                                <select
                                                    id="branchSelect"
                                                    className="form-control"
                                                    value={selectedBranchId}
                                                    onChange={handleBranchChange}
                                                >
                                                    {Array.isArray(userbranchData) ? (
                                                        userbranchData.map((branch) => (
                                                            <option key={branch.id_branch} value={branch.id_branch}>
                                                                {branch.id_branch} - {branch.branch_name}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option disabled>No branches available</option>
                                                    )}
                                                </select>
                                            </div>

                                            <h5 className='mb-1' >Your secret key</h5>
                                            <p className='req-info'>{requiredDataUser.secretKey}</p>


                                            <h5 className='mb-1' >Tax Organizations</h5>
                                            <p>we only accept these tax organizations</p>
                                            <p className='req-info'>FBR, SRB, PRA, BRA, KPRA</p>
                                        </div>
                                        <div className='col-12 col-lg-8 col-md-8 col-sm-12'>
                                            {/* Tabs for Request, Response, and cURL */}
                                            <div className="tabs">
                                                <button
                                                    className={`tab-button ${activeTab === 'request' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('request')}
                                                >
                                                    Request (JSON Structure)
                                                </button>

                                                <button
                                                    className={`tab-button ${activeTab === 'js-request' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('js-request')}
                                                >
                                                    JS Request
                                                </button>

                                                <button
                                                    className={`tab-button ${activeTab === 'curl' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('curl')}
                                                >
                                                    cURL
                                                </button>

                                                <button
                                                    className={`tab-button ${activeTab === 'response' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('response')}
                                                >
                                                    Response (200)
                                                </button>
                                            </div>

                                            {/* Content based on selected tab */}
                                            <div className="tab-content">
                                                {activeTab === 'request' && (
                                                    <div className="json-structure">
                                                        <button
                                                            disabled
                                                            className="tab-button-request"
                                                        >
                                                            POST ({process.env.REACT_APP_BASE_URL}/insert-receipt)
                                                        </button>
                                                        <pre>{`{
    "invoice_number": "inv-112235",
    "userId": ${requiredDataUser.id},
    "date": "2024-06-14 11:30:00",
    "business_id": ${requiredDataUser.businessId},
    "branch_id": ${selectedBranchId},
    "taxation_code": "SNTN: 00000000</BR>",
    "ims_code": "804236DF6N3s9554356*test*",
    "tax_org": "SRB",
    "tax_org_link": "https://pos.srb.gos.pk/InvoiceVerification/InvoiceStatus.jsp?invoiceVerification=669241107145559724",
    "customer_name": "Ahmed ali",
    "customer_cell": "12345678",
    "customer_cnic": "1243214324",
    "customer_email": "demo@gmail.com",
    "invoice_status": "Active",
    "payment_mode": "Online",
    "instrument_number": "Online 956344",
    "cashier": "Cashier1",
    "subtotal_amount": 3000.00,
    "discount": 100,
    "total_before_tax": 2900,
    "total_tax": 300.000,
    "total_after_tax": 3200.00,
    "paid_amount": 3200,
    "remaining_amount": 0,
    "points_earned":19,
    "points_used":80,
    "total_points":13.89,
    "detail": [
        {
            "staff_id": 1,
            "staff_name":"hira khan",
            "deal_name":"deal-1",
            "product_name": "SnowWhitening Pedicure",
            "barcode": "512",
            "unit_price": 1800,
            "qty": 1,
            "discount_percentage": 0,
            "tax_percentage": 9.09111111111111,
            "tax_amount": 163.6400,
            "discount_amount": 0,
            "total_price": 1800.02
        },
        {
            "staff_id": 1,
            "staff_name":"hira khan",
             "deal_name":"deal-1",
            "product_name": "Regular nail paint Feet",
            "barcode": "162",
            "unit_price": 500,
            "qty": 1,
            "discount_percentage": 0,
            "tax_percentage": 9.092,
            "tax_amount": 45.4600,
            "discount_amount": 0,
            "total_price": 500.01
        },
        {
            "staff_id": 1,
            "staff_name":"hira khan",
            "deal_name":"",
            "product_name": "Hair wash",
            "barcode": "483",
            "unit_price": 500,
            "qty": 1,
            "discount_percentage": 0,
            "tax_percentage": 9.092,
            "tax_amount": 45.4600,
            "discount_amount": 0,
            "total_price": 500.01
        },
        {
            "staff_id": 1,
            "staff_name":"hira khan",
            "deal_name":"",
            "product_name": "Hand Scrub",
            "barcode": "536",
            "unit_price": 500,
            "qty": 1,
            "discount_percentage": 0,
            "tax_percentage": 9.092,
            "tax_amount": 45.4600,
            "discount_amount": 0,
            "total_price": 500.01
        }
    ]
}`}</pre>
                                                    </div>
                                                )}

                                                {activeTab === 'js-request' && (
                                                    <div className="json-structure">
                                                        <button
                                                            disabled
                                                            className="tab-button-request"
                                                        >
                                                            JS Request (insert-receipt)
                                                        </button>
                                                        <pre>{`var settings = {
  "url": "${process.env.REACT_APP_BASE_URL}/insert-receipt",
  "method": "POST",
  "timeout": 0,
  "headers": {
    "secret-key": "${requiredDataUser.secretKey}"
  },
  "data": Send your json structure with data parameter ,
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`}</pre>
                                                    </div>
                                                )}

                                                {activeTab === 'response' && (
                                                    <div className="json-structure">
                                                        <pre>{`{
    "message": "Data inserted successfully",
    "receipt_code": "6la1k1",
    "reciept_url": "${process.env.REACT_APP_BASE_URL}/6la1k1"
}`}</pre>
                                                    </div>
                                                )}

                                                {activeTab === 'curl' && (
                                                    <div className="json-structure">
                                                        <pre>{`curl --location '${process.env.REACT_APP_BASE_URL}/insert-receipt' \

--header 'secret-key: ${requiredDataUser.secretKey}' \
--data '{
   Set your json structure or request parameters here..
    ]
}'`}</pre>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </section>
                    )}
                    {/* <Footer /> */}
                    <section id="new-footer-btm" className='print-hidden'>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="fop-btm text-center">
                                        <h2>Copyright &copy; {new Date().getFullYear()}. All rights reserved by <a href="https://www.mexyon.com/">Mexyon</a></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </div >
        </>
    );
};

export default CustomerView;
